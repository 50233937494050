export enum AnalyticsEventType {
  PLP_VIEW = 'PLP_VIEW',
  PDP_VIEW = 'PDP_VIEW',
  OTHER_PAGE = "OTHER_PAGE",
  HOME_PAGE = "HOME_PAGE"
}

export const AnalyticsType = {
  KWIKPASS: 'kwikpass',
}
